import { AntDesignOutlined,BarChartOutlined, CarFilled, FormatPainterOutlined, MoneyCollectFilled, ProfileOutlined, UserOutlined, } from '@ant-design/icons';
import React from 'react';
import { RoutesScreen } from '../screens/routes.screen';
import { DriversScreen } from '../screens/drivers.screen';
import { RidesScreen } from '../screens/rides.screen';

export const OrgRoutes = [
    {
        link: "routes",
        name: "Routes",
        icon: <AntDesignOutlined />,
        screen: <RoutesScreen />,
    },
    {
        link: "",
        name: "Drivers",
        icon: <CarFilled />,
        screen: <DriversScreen />
    },
    // {
    //     link: "models",
    //     name: "Models",
    //     icon: <CarFilled />,
    //     screen: <ModelsScreen />
    // },
    // {
    //     link: "payments",
    //     name: "Payments",
    //     icon: <MoneyCollectFilled />,
    //     screen: <PaymentsScreen />
    // },
    {
        link: "rides",
        name: "Rides",
        icon: <CarFilled />,
        screen: <RidesScreen />
    },
]