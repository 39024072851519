import { Button, Form, Input, Modal, Select, Typography } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons'
import React, { useContext, useEffect, useRef, useState } from 'react';
import { RoutesCol } from '../columns/routes.cols';
import { TxyTable } from '../components/table.component';
import { RoutesData } from '../constants/datas';
import { useGetPaginated, useGet, usePost } from '../hooks/useRest.hook';
import { EndPoints } from '../constants/endpoints';
import { Loader } from '../components/loader.component';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { toast } from 'react-toastify';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { UserTypes } from '../constants/general';
import { UserContext } from '../App';
import { useForm } from 'antd/lib/form/Form';

const CAPE_TOWN_LOC = {
    lat: -33.918861,
    lng: 18.423300
};

export const RoutesScreen = () => {
    const [addModal, setAddModal] = useState(false);
    const [assignModal, setAssignModal] = useState(false);
    const [routeId, setRouteId] = useState(null);
    const [editModal, setEditModal] = useState(false);
    const { user } = useContext(UserContext);

    const assignRoute = id => {
        setAssignModal(true);
        setEditModal(false);
        setRouteId(id);
    }
    const openEditModal = id => {
        setEditModal(id);
    }

    const routesRequest = useGetPaginated(EndPoints.ROUTES);
    return <div className='flex flex-col w-full'>
        <Typography.Title className='mb-5'>Routes</Typography.Title>
        {routesRequest.loading ? <Loader /> : <>
            {user.type === UserTypes.ADMIN &&  <div className='w-full flex flex-row justify-end mb-5'><Button onClick={() => setAddModal(true)} type='primary'>Add Route</Button></div> }
            {addModal && <AddRoute modalVisible={addModal} onCancel={() => setAddModal(false)} onOk={() => {
                routesRequest.load(routesRequest.pageNo, routesRequest.pageSize);
                setAddModal(false);
            }} />}
            {assignModal && <AssignRoute modalVisible={assignModal} routeId={routeId} onAssign={() => { setAssignModal(false); routesRequest?.refresh(); }} onCancel={() => setAssignModal(false)} />}
            {editModal && <EditOnboarding driverAdd={assignRoute} setVisible={setEditModal} visible={editModal} routeId={editModal} />}
            <TxyTable cols={RoutesCol(assignRoute, openEditModal, (record) => setAddModal(record), user?.type)} {...routesRequest} />
        </>}
    </div>
}

export const TXYPlacesSelect = ({ name, label, hint, options, required, className, noMb, onPlaceSelect, type = "text" }) => {
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        options: {

        }
    });
    const onSelect = (placeId, lbl) => {
        var place = placesService.getDetails({
            placeId,
        }, place => onPlaceSelect(lbl, place)
        );

    }
    const handleSearch = (search) => {
        getPlacePredictions({ input: search, })
    }
    return <Form.Item name={name} className={`${noMb ? '' : 'mb-0'} w-full ${className}`} label={label} rules={required ? [{ message: `${label} is required`, required: true }] : undefined}>
        <Select
            showSearch
            placeholder={hint || label}
            loading={isPlacePredictionsLoading}
            defaultActiveFirstOption={false}
            onSelect={onSelect}
            showArrow={false}
            className={hint && "hint"}
            style={{ width: '100%' }}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
            options={(placePredictions || []).map((d) => ({
                value: d.place_id,
                label: d.description,
            }))}
        />
    </Form.Item>
}

const AssignRoute = ({ modalVisible, routeId, onAssign, onCancel }) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [search, setSearchText] = useState('');
    const driverRequest = useGet(EndPoints.SEARCH_DRIVER(search));
    const assignRequest = usePost(EndPoints.ASSIGN_DRIVER);

    const onFinish = async (values) => {
        console.log('Received values of form: ', values);
        values.TravelRouteId = routeId;
        let res = await assignRequest.post(values);
        toast.success(res.message);
        onAssign();
    }
    return <Modal title="Assign Route" footer={null} visible={modalVisible} onCancel={onCancel}>
        <Form onFinish={onFinish} className='w-full' layout='vertical'>

            <Form.Item rules={[{ required: true, message: "Please select a driver" }]} label="Driver" name="VehicleId"><Select
                className='w-full'
                showSearch
                placeholder="Select Driver"
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
                onSearch={(s) => {
                    if (s)
                        setSearchText(s)
                }}
                onChange={(e) => setSelectedUser(e)}
            >
                {driverRequest?.data?.map && driverRequest?.data?.map(d => <Select.Option key={d.id} >[{d?.noPlate}]: {d.user?.name} - {d?.model}</Select.Option>)}
            </Select>
            </Form.Item>
            <div className='w-full flex flex-row justify-end'>
                <Button className='mr-5' onClick={onCancel}>Cancel</Button>
                <Button loading={assignRequest.loading} htmlType='submit' type='primary'>Add</Button>
            </div>
        </Form>
    </Modal>
}

const EditOnboarding = ({ visible, routeId, setVisible, driverAdd }) => {
    const assignedDrivers = useGet(EndPoints.GET_ASSIGNED_DRIVERS(routeId));
    const delDriverRq = usePost(EndPoints.DEL_ASSIGN_DRIVER);

    const _removeDriver = async id => {
        if (!window.confirm("Are you sure you want to deassign this driver?")) return;
        const formData = new FormData();
        formData.append("vehicleRouteId", id);
        try {
            let data = await delDriverRq.post(formData);
            assignedDrivers?.refresh();
            toast.success("Driver removed successfully!");
        } catch (e) {
            toast.error(e);
        }
    }

    return <Modal footer={null} width={"70%"} title="Driver onboarding" visible={true} onCancel={setVisible?.bind(this, false)}>
        {assignedDrivers?.loading || delDriverRq?.loading ? <div className="flex flex-row justify-center items-center"> <Loader /></div> :
            <div className="flex flex-col gap-5">
                <span>Drivers</span>
                <div className="grid grid-cols-3 gap-5 ">{assignedDrivers?.data?.map?.(M => <div className="flex flex-row pl-5 items-center border justify-between"><span className="" >{M?.vehicle?.user?.name}</span><Button onClick={_removeDriver.bind(this, M?.id)} style={{ color: 'red' }} icon={<CloseCircleFilled />} /> </div>)}
                </div>
                <div className="flex flex-row justify-end">
                    <Button onClick={() => driverAdd(routeId)} className="w-1/3">Add Driver</Button>
                </div>
            </div>}
    </Modal>

}

const AddRoute = ({ modalVisible, onOk, onCancel }) => {
    const fromInputRef = useRef(null);
    const toInputRef = useRef(null);
    const [form] = useForm();
    const [fromObj, setFromObj] = useState(null);
    const [toObj, setToObj] = useState(null);
    const request = usePost(modalVisible?.id ? EndPoints.UPDATE_ROUTE: EndPoints.ADD_ROUTE);
    console.log("fromObj", fromObj, "toObj", toObj);
    const onFinish = async (values) => {
        if (parseInt(values.fare) <= 0) {
            toast.error("Please enter fare more than 0");
            return;
        }

        console.log('Received values of form: ', values);
        if (fromObj?.lat === null || toObj?.lat === null) {
            toast.warn("Please select both from and to locations from the map");
            return;
        }

        

        const data = {
            DestinationName: toObj?.to,
            DepartureName: fromObj?.from,
            Amount: values.fare,
            DestinationLatitude: toObj?.lat,
            DestinationLongitude: toObj?.lng,
            DepartureLatitude: fromObj?.lat,
            DepartureLongitude: fromObj?.lng,
        }
        if (modalVisible?.id) {
            data.Id = modalVisible?.id
        }

        let res = await request.post(data);
        toast.success(res.message);
        onOk();
    }

    useEffect(() => {
        if (modalVisible?.id) {
            setFromObj({
                from: modalVisible?.departureName,
                lat: modalVisible?.departureLatitude,
                lng: modalVisible?.departureLongitude
            });
            setToObj({
                to: modalVisible?.destinationName,
                lat: modalVisible?.destinationLatitude,
                lng: modalVisible?.destinationLongitude
            });
            form.setFieldsValue({
                fare: modalVisible.amount
            });
        } else {
            setFromObj(null);
            setToObj(null);
            form.resetFields();
        }
    },[modalVisible])



    return <Modal title={`${modalVisible?.id?"Update":"Add"} Route`} width={700} footer={null} style={{ width: '700px' }} visible={modalVisible} onOk={onOk} onCancel={onCancel}>
        <Form form={form} onFinish={onFinish} layout='vertical'>
            <div className='flex flex-row mb-5 gap-5'>
                <div className='flex flex-1 w-1/2 flex-col'>
                    <div className="w-full">
                        <TXYPlacesSelect hint={fromObj?.from} name="from" label="From" onPlaceSelect={(desc, place) => {
                            console.log('place', place);
                            setFromObj({
                                from: desc.label,
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng()
                            })
                        }} />
                    </div>
                    <div style={{ height: 200, width: '100%' }}>
                        {fromObj?.lat == null ? <span>Please select a address to show on the map</span> :
                            <GoogleMap
                                mapContainerStyle={{ height: 200 }}
                                center={{ lat: fromObj.lat, lng: fromObj.lng }}
                                zoom={15}
                            >
                                <Marker
                                    position={{ lat: fromObj.lat, lng: fromObj.lng }}
                                />
                            </GoogleMap>
                        }
                    </div>
                </div>
                <div className='flex flex-1 w-1/2 flex-col'>
                    <div className="w-full">

                        <TXYPlacesSelect hint={toObj?.to} name="to" label="To" onPlaceSelect={(desc, place) => {
                            setToObj({
                                to: desc.label,
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng()
                            })
                        }} />
                    </div>
                    <div style={{ height: 200, width: '100%' }}>
                        {toObj?.lat == null ? <span>Please select a address to show on the map</span> :
                            <GoogleMap
                                mapContainerStyle={{ height: 200 }}
                                center={{ lat: toObj.lat, lng: toObj.lng }}
                                zoom={15}
                            >
                                <Marker
                                    position={{ lat: toObj.lat, lng: toObj.lng }}
                                />
                            </GoogleMap>
                        }
                    </div>

                </div>
            </div>
            <Form.Item label="Fare" name="fare" rules={[{ required: true }]}>
                <Input type="text" min={1} step={1} onKeyDown={event => {
                    if (!/[0-9]/.test(event.key) && event.key.toLowerCase() !== "backspace" && event.key.toLowerCase() !== "delete" && !event.key.toLowerCase().startsWith("arrow")) {
                        event.preventDefault();
                    }
                }}
                />
            </Form.Item>
            <div className='w-full flex flex-row justify-end'>
                <Button className='mr-5' onClick={onCancel}>Cancel</Button>
                <Button loading={request?.loading} htmlType='submit' type='primary'>{modalVisible?.id ?"Update":"Add"}</Button>
            </div>
        </Form>
    </Modal>
}