import { Button } from 'antd';
import React from 'react';

export const DriversCols = (editDriver, remove) => [
    {
        title:"ID",
        dataIndex:'id',
        key:"user?.id",
        width:'10%'
    },
    {
        title: "Full Name",
        dataIndex:['user','name'],
        key:'from',
        width:'10%'
    },
    {
        title: "Email",
        dataIndex:['user','email'],
        key:'to',
        width:'10%'
    },
    // {
    //     title: "Organization",
    //     dataIndex:['user','organization','name'],
    //     key:'org',
    //     width:'10%'
    // },
    {
        title: "Vehicle",
        dataIndex:'model',
        key:'vehicle',
        width:'10%'
    },
    {
        title: "Phone",
        dataIndex:['user','phonenumber'],
        key:'phone',
        width:'10%'
    },
    {
        title: "Registration",
        dataIndex:'noPlate',
        key:'register',
        width:'10%',
    },
    {
        title: "Actions",
        dataIndex:'actions',
        key:'actions',
        render: (text, record) => <div className="flex flex-row gap-5">
            <Button onClick={() => editDriver(record)} type='primary'>Edit</Button>
            <Button onClick={() => remove(record)} style={{color:'white', backgroundColor:'red'}}>Delete</Button>
        </div>,
        width:'20%'
    },
]
