import { Button } from "antd";
import { Constants } from "../constants/enums";

export const RidesCols = onUpdate => [
    {
        title: "ID",
        dataIndex: 'id',
        key: "id",
        width: '10%'
    },
    {
        title: "By",
        dataIndex: ['user', 'name'],
        key: 'from',
        width: '20%'
    },
    {
        title: "Route",
        render: (text, record) => <span>{record?.vehicleRoute?.travelRoute?.departureName} -&gt; {record?.vehicleRoute?.travelRoute?.destinationName} </span>,
        key: 'amount',
        width: '20%'
    },
    {
        title: "Status",
        render: text => Constants.OrderStatusInfo[text]?.name,
        dataIndex: "status"
    },
    {
        title: "Amount Paid",
        dataIndex: 'amount',
        render: (text) => text ?? 0,
        key: 'amountPaid',
        width: '20%'
    },
    {
        title: "Booked On",
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: text => new Date(text).toLocaleString(),
        width: '10%'
    },
    {
        title: "Actions",
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => [Constants.OrderStatus.Accepted, Constants.OrderStatus.Pending, Constants.OrderStatus.Started].find(F => F === record?.status)? <div className="flex flex-row items-center"><Button onClick={() => onUpdate(record?.id, record?.status)}>Update State</Button> </div>:<></>,
        width: '20%'
    },
]