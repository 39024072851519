import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.css';
import React, { useEffect } from 'react';
import { Routes as DashboardRoutes } from './navigator/dashboard.router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DashboardScreen } from './screens/dashboard.screen';
import { useState } from 'react';
import { LoginScreen } from './screens/login.screen';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadScriptNext } from '@react-google-maps/api';

import axios from 'axios';
import { EndPoints } from './constants/endpoints';
import { useGet } from './hooks/useRest.hook';
import { FullLoader } from './components/loader.component';
import { GeneralConstants, UserTypes } from './constants/general';
import { OrgRoutes } from './navigator/org.router';
axios.defaults.baseURL = EndPoints.BASE_URL;
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;

const libraries = ["places"];



axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    localStorage.removeItem('token');
    return Promise.reject(error);
  }
  toast.error(error?.response?.data?.message ?? "An Unknown Error Occurred");
  return Promise.reject(error);
});
export const UserContext = React.createContext({});
function App() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (localStorage.getItem('token')) {
      axios.get(EndPoints.ME)
        .then(res => {
          if (res?.data?.success) {
            setUser(res.data.data);
          }
        })
        .finally(() => setLoading(false));
    } else setLoading(false);
  }, []);
  if (loading) {
    return <FullLoader />;
  }
  return (
    <LoadScriptNext
      libraries={libraries}
      googleMapsApiKey={GeneralConstants.GOOGLE_MAP_API_KEY}>
      <UserContext.Provider value={{ user, setUser }}>
        <BrowserRouter>
          <ToastContainer />
          {user?.id ?
            <Routes>
              <Route path="/" element={<DashboardScreen Routes={(user?.type === UserTypes.ADMIN ? DashboardRoutes : OrgRoutes)} />}>
                {(user?.type === UserTypes.ADMIN ? DashboardRoutes : OrgRoutes).map(R => <Route path={R.link} element={R.screen} />)}
              </Route>
            </Routes>
            : <LoginScreen />}
        </BrowserRouter>
      </UserContext.Provider>
    </LoadScriptNext>
  );
}

export default App;
