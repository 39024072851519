import { Button, Card, Form, Input } from 'antd';
import React, { useContext } from 'react';
import { UserContext } from '../App';
import { Assets } from '../assets';
import { EndPoints } from '../constants/endpoints';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
export const LoginScreen = () => {
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const login = payload => {
        axios.post(EndPoints.LOGIN, payload)
            .then(res => {
                if (res?.data?.success) {
                    const { user, token } = res?.data?.data;
                    localStorage.setItem("token", token);
                    axios.defaults.headers.common.Authorization = 'Bearer ' + token;
                    setUser(user);
                    navigate('/', {
                        replace:true
                    });
                }
            })
    }
    return <div style={{ height: '100vh' }} className="bg-gray-200 w-full flex justify-center items-center">
        <Card className='w-1/3' title="Login">
            <div className='grid grid-cols-2'>
                <div className='flex justify-center items-center'>
                    <img src={Assets.logo} className="object-contain h-32" />
                </div>
                <div className=''>
                    <Form onFinish={login} layout='vertical'>
                        <Form.Item name={"email"} required rules={[{required:true, message:"Please Enter Email"}]} label="Email">
                            <Input />
                        </Form.Item>
                        <Form.Item name={"password"} required rules={[{required:true, message:"Please Enter Password"}]} label="Password">
                            <Input type="password" />
                        </Form.Item>
                        <Button className='float-right' type='primary' htmlType='submit'>Sign In</Button>
                    </Form>
                </div>
            </div>
        </Card>
    </div>
}