const BASE_LIVE ='https://api.txyride.com';
const BASE_QA = 'http://160.119.254.32:4304' 
const BASE_LOCAL = 'http://localhost:5289'
export const EndPoints = {
    BASE_URL: BASE_LIVE,
    LOGIN: '/api/auth/admin-login',
    BRANDS: '/api/vehicles/get-brands',
    VEHICLES: '/api/vehicles/get-vehicles',
    ROUTES: '/api/vehicles/admin-allroutes',
    USERS: "/api/users/admin-allUsers",
    ORGS: "/api/users/orgs",
    EDIT_ORG: id => `/api/users/update-org/${id}`,
    ADD_ORG: '/api/users/register-org',
    ME: "/api/users/token-user",
    METRICS: "/api/users/dashboard-overview",
    BRANDS_DIST: "/api/users/brand-distribution",
    ADD_ROUTE:"/api/vehicles/add-travelRoute",
    UPDATE_ROUTE:"/api/vehicles/update-route",
    PAYMENTS: "/api/funds/userFunds",
    SEARCH_DRIVER: name => `/api/vehicles/search-vehicle?label=${name}`,
    ASSIGN_DRIVER: "/api/vehicles/assign-route",
    UPDATE_PASSWORD: "/api/users/change-password",
    ADD_BRAND: "/api/vehicles/add-brand",
    DELETE_BRAND: "/api/vehicles/delete-brand",
    SEARCH_BRAND: name => `/api/vehicles/search-brand?name=${name}`,
    ADD_DRIVER: '/api/users/driver-register',
    UPDATE_DRIVER: id => `/api/vehicles/update-driver-vechicle/${id}`,
    DELETE_DRIVER:  `/api/vehicles/delete-driver`,
    GET_ASSIGNED_DRIVERS: id =>  `/api/vehicles/get-assigned-drivers?routeId=${id}`,
    EDIT_BRAND: "/api/vehicles/edit-brand",
    UPDATE_USER: "/api/users/update-user",
    ALL_ORDERS: "/api/booking/all-orders",
    DEL_ASSIGN_DRIVER: "/api/vehicles/delete-route",
    UPDATE_ORDER: "/api/booking/update-order"
}