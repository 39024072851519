const OrderStatus = {
    Pending: 0,
    DriverRejected: 1,
    UserRejected: 2,
    Accepted: 3,
    Started: 4,
    Completed: 5

}
const OrderStatusInfo = [
    { name: "Pending" }, { name: "Rejected By Driver" }, { name: "Rejected By User" }, { name: "Accepted" }, { name: "Started" }, { name: "Completed" }
]

export const Constants = {
    OrderStatus,
    OrderStatusInfo
}