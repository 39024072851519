import { Button } from "antd";
import React from 'react';

export const UserCols = (edit,_password) => [
    {
        title:"ID",
        dataIndex:'id',
        key:"id",
        width:'10%'
    },
    {
        title: "Full Name",
        dataIndex:'name',
        key:'from',
        width:'20%'
    },
    
    {
        title: "Phone",
        dataIndex:'phonenumber',
        key:'phone',
        width:'10%'
    },

    {
        title: "Email",
        dataIndex:'email',
        key:'to',
        width:'10%'
    },
    
    {
        title: "Member Since",
        dataIndex:'createdAt',
        render: text => new Date(text).toLocaleDateString(),
        key:'fare',
        width:'10%'
    },
    {
        title: "Actions",
        dataIndex:'actions',
        key:'actions',
        render: (text, record) => <div className="flex flex-row gap-5">
            <Button onClick={() => edit(record)} type='primary'>Edit</Button>
            <Button onClick={() => _password(record)} type='primary'>Update Password</Button>
        </div>,
        width:'10%'
    },
    
]