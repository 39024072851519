import { Table } from "antd"

export const TxyTable = ({load, pageNo, totalCount,pageSize, name, cols, list:data}) => {
    return <Table
        columns={cols}
        pagination={{
            pageSize: pageSize,
            current: pageNo,
            total: totalCount,
            onChange: (pageNo, pageSize) => {
               load(pageNo,pageSize);
            }
        }
        }
        dataSource={data}
        style={{width:'100%'}}
        />
}