import { Button, Form, Input, Modal, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { BrandsCols } from '../columns/brands.cols';
import { BrandsData } from '../constants/datas';
import { TxyTable } from '../components/table.component';
import { useGetPaginated, usePost } from '../hooks/useRest.hook';
import { EndPoints } from '../constants/endpoints';
import { Loader } from '../components/loader.component';
import { toast } from 'react-toastify';
export const BrandsScreen = () => {
    const [addModal, setAddModal] = useState(false);
    const [brand, setBrand] = useState(null);
    const brandsRequest = useGetPaginated(EndPoints.BRANDS, true);
    const delBrand = usePost(EndPoints.DELETE_BRAND);
    const edit = (brand) => {
        setAddModal(true);
        setBrand(brand);
    }
    const _delete = async brand => {
        if (!window.confirm("Are you sure you want to delete this brand?")) {
            return;
        }
        const formData = new FormData();
        formData.append("id", brand?.id);
        try {
            var data = await delBrand?.post(formData);
            if (data) {
                toast.success("Brand deleted successfully!");
            }
            brandsRequest?.refresh();
        } catch (e) {
        }
    }
    return <div className='flex flex-col w-full'>
        <Typography.Title className='mb-5'>Brands</Typography.Title>
        {(brandsRequest.loading || delBrand?.loading) ? <Loader /> : <>
            <div className='w-full flex flex-row justify-end mb-5'><Button onClick={() => setAddModal(true)} type='primary'>Add Brand</Button></div>
            {addModal && <AddBrand brand={brand} modalVisible={addModal}
                onCancel={() => setAddModal(false)}
                onOk={() => {
                    brandsRequest.load(brandsRequest.pageNo, brandsRequest.pageSize);
                    setAddModal(false);
                }
                } />}
            <TxyTable {...brandsRequest} cols={BrandsCols(edit, _delete)} />
        </>
        }
    </div>
}

const AddBrand = ({ modalVisible, onOk, onCancel, brand }) => {
    const addRequest = usePost(brand?.id ? EndPoints.EDIT_BRAND : EndPoints.ADD_BRAND);
    const onFinish = async values => {
        values.id = brand?.id;
        await addRequest.post(values);
        onOk();
    }
    const [form] = Form.useForm();
    useEffect(() => {
        console.log("Setting Brand Edit Modal", brand);
        if (brand) {
            form.setFieldsValue(brand);
        } else {
            form.resetFields();
        }
    }, [brand])
    return <Modal title={brand?.id ? "Add Brand" : "Edit Brand"} footer={null} visible={modalVisible} onCancel={onCancel}>
        <Form form={form} onFinish={onFinish} layout='vertical'>

            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <div className='w-full flex flex-row justify-end'>
                <Button className='mr-5' onClick={onCancel}>Cancel</Button>
                <Button htmlType='submit' loading={addRequest?.loading} type='primary'>{brand?.id ? "Edit" : "Add"}</Button>
            </div>
        </Form>
    </Modal>
}