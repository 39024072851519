import { Button, Form, Input, Modal, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { UserCols } from '../columns/users.cols';
import { TxyTable } from '../components/table.component';
import { UsersData } from '../constants/datas';
import { EndPoints } from '../constants/endpoints';
import { useGetPaginated, usePost } from '../hooks/useRest.hook';
import { useEffect } from 'react';
import { Loader } from '../components/loader.component';
import { toast } from 'react-toastify';
export const UsersScreen = () => {
    const [user, setUser] = useState(null);
    const [pUser, setPUser] = useState(null);
    const userRequest = useGetPaginated(EndPoints.USERS);

    return <div className='flex flex-col w-full'>
        <Typography.Title className='mb-5'>Users</Typography.Title>
        {(userRequest?.loading) ? <Loader /> : <>

            <TxyTable cols={UserCols(setUser,setPUser)} {...userRequest} />
            <EditUser modalVisible={user} onOk={
                () => {
                    setUser(null);
                    userRequest?.refresh();
                }
            }
                onCancel={setUser.bind(this, null)}
                user={user}
            />
            <EditUserPassword modalVisible={pUser} onOk={
                () => {
                    setPUser(null);
                    userRequest?.refresh();
                }
            }
                onCancel={setPUser.bind(this, null)}
                user={pUser}
            />
        </>}
    </div>
}


const EditUserPassword = ({ modalVisible, onOk, onCancel, user }) => {
    const updateRq = usePost(EndPoints.UPDATE_PASSWORD);
    const onFinish = async values => {
        values.userID = user?.id;
        if (values.password !== values.cPassword) {
            toast.error("Password do not match!");
            return;
        }

        await updateRq.post(values);
        toast.success("Password updated successfully!");
        onOk();
    }
    const [form] = Form.useForm();
   
    return <Modal title={"Update Password"} footer={null} visible={modalVisible} onCancel={onCancel}>
        <Form form={form} onFinish={onFinish} layout='vertical'>

            <Form.Item label="Password" name="password" rules={[{ required: true }]}>
                <Input type="password" />
            </Form.Item>
            
            <Form.Item label="Confirm Password" name="cPassword" rules={[{ required: true }]}>
                <Input type="password" />
            </Form.Item>
            
            <div className='w-full flex flex-row justify-end'>
                <Button className='mr-5' onClick={onCancel}>Cancel</Button>
                <Button htmlType='submit' loading={updateRq?.loading} type='primary'>{"Edit"}</Button>
            </div>
        </Form>
    </Modal>
}

const EditUser = ({ modalVisible, onOk, onCancel, user }) => {
    const updateRq = usePost(EndPoints.UPDATE_USER);
    const onFinish = async values => {
        values.id = user?.id;
        await updateRq.post(values);
        onOk();
    }
    const [form] = Form.useForm();
    useEffect(() => {
        console.log("Setting User Edit Modal", user);
        if (user) {
            form.setFieldsValue(user);
        } else {
            form.resetFields();
        }
    }, [user])
    return <Modal title={"Edit User"} footer={null} visible={modalVisible} onCancel={onCancel}>
        <Form form={form} onFinish={onFinish} layout='vertical'>

            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                <Input type="email" />
            </Form.Item>
            <div className='w-full flex flex-row justify-end'>
                <Button className='mr-5' onClick={onCancel}>Cancel</Button>
                <Button htmlType='submit' loading={updateRq?.loading} type='primary'>{"Edit"}</Button>
            </div>
        </Form>
    </Modal>
}