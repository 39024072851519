    import React, { useEffect, useState } from 'react';
    import axios from 'axios';

    export const usePost = (endpoint) => {
        const [loading, setLoading] = useState(false);

        const postData = async (payload) => {
            setLoading(true);
            console.log("Posting to " + endpoint, payload);
            try {
                let { data } = await axios.post(endpoint, payload);
                console.log("[POST " + endpoint + "] Response", data);
                if (data?.success) {
                    setLoading(false);
                    
                    return data?.data;
                }
                else throw data?.message;
            } catch (e) {
                console.log("Post Error: ", e);
                setLoading(false);
                throw e;
            }
            setLoading(false);
        }

        return { post: postData, loading };
    }

    export const usePut = (endpoint) => {
        const [loading, setLoading] = useState(false);

        const postData = async (payload) => {
            setLoading(true);
            try {
                let { data } = await axios.put(endpoint, payload);
                if (data?.success) {
                    setLoading(false);
                    return data?.data;
                }
                else throw data?.message;
            } catch (e) {
                console.log("Post Error: ", e);
            }
            setLoading(false);
        }

        return { post: postData, loading };
    }

    export const useGet = (endpoint, loadInstantly = true) => {
        const [loading, setLoading] = useState(loadInstantly);
        const [data, setData] = useState({});

        const loadData = () => {
            setLoading(true);
            axios.get(endpoint)
                .then(res => {
                    if (res?.data?.success) {
                        setLoading(false);
                        setData(res?.data?.data);
                    }
                })
        }
        useEffect(() => {
            if (loadInstantly) loadData();
        }, [endpoint])
        return { loading, data, refresh: loadData };
    }

    export const useGetPaginated = (endpoint, loadInstantly = true,) => {
        const [loading, setLoading] = useState(loadInstantly);
        const [data, setData] = useState({});

        const loadData = (pNo = 1, pSize = 10) => {
            setLoading(true);
            axios.get(endpoint + (/\?/.test(endpoint) ? "&" : "?") + `PageNo=${pNo}&PageSize${pSize}`)
                .then(res => {
                    if (res?.data?.success) {
                        setData(res?.data?.data);
                        setLoading(false);
                    }
                })
        }
        const refresh  = () => {
            loadData(data?.currentPage, data?.pageSize);
        }

        useEffect(() => {
            if (loadInstantly) loadData();
        }, [endpoint]);

        return { list: data?.list,refresh, pageNo: data?.currentPage, pageSize: data?.pageSize, totalCount: data?.totalCount, totalPages: data?.totalPages, loading, load: loadData }
    }