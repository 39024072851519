import { Button, Typography, Modal, Form, Input, Select } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DriversCols } from '../columns/drivers.cols';
import { TxyTable } from '../components/table.component';
import { UsersData } from '../constants/datas';
import { useGetPaginated, useGet, usePost } from '../hooks/useRest.hook';
import { EndPoints } from '../constants/endpoints';
import { Loader } from '../components/loader.component';
import { toast } from 'react-toastify';
import { UserContext } from '../App';
import { UserTypes } from '../constants/general';
export const DriversScreen = () => {
    const { user } = useContext(UserContext);

    const driversRequest = useGetPaginated(EndPoints.VEHICLES);
    const delRq = usePost(EndPoints.DELETE_DRIVER);
    const orgRq = useGet(EndPoints.ORGS + "?pageNo=1&pageSize=1000",false);//user.type === UserTypes.ADMIN);
    const [addModal, setAddModal] = useState(false);
    const [driverEdit, setDriverEdit] = useState({});

    const _driverEdit = driver => {
        setAddModal(true);
        setDriverEdit(driver);
    }

    const _delete = async driver => {
        if (!driver?.id || !window.confirm("Are you sure to delete the driver?")) return;

        try {
            var formData = new FormData();
            formData.append("id", driver?.user?.id);

            var data = await delRq.post(formData);
            if (data) {
                toast.success("Driver deleted successfully!");
                driversRequest?.refresh();
            }
        } catch (e) {
            console.error(e);
        }

    }
    return <div className='flex flex-col w-full'>
        <Typography.Title className='mb-5'>Drivers</Typography.Title>
        <div className='w-full flex flex-row justify-end mb-5'><Button onClick={() => {
            setAddModal(true);
            setDriverEdit({});
        }} type='primary'>Add Driver</Button></div>
        {addModal && <AddDriver orgRq={orgRq} driver={driverEdit} modalVisible={addModal} onCancel={() => {
            setAddModal(false);
            setDriverEdit({});
        }} onOk={() => {
            setAddModal(false);
            setDriverEdit({});
            driversRequest.refresh();
        }} />}
        {driversRequest.loading ? <Loader /> : <TxyTable cols={DriversCols(_driverEdit, _delete)} {...driversRequest} />}

    </div>
}


const AddDriver = ({ modalVisible, onOk, onCancel, driver, orgRq }) => {
    const [searchText, setSearchText] = useState('');
    const { user } = useContext(UserContext);
    const brandRequest = useGet(EndPoints.SEARCH_BRAND(searchText));
    const driverRequest = usePost(driver?.id ? EndPoints.UPDATE_DRIVER(driver.id) : EndPoints.ADD_DRIVER);
    const [form] = Form.useForm();
    const onFinish = async values => {
        if (user.type === UserTypes.ORG) {
            values.OrgId = user.id
        }
        console.log("Add Driver Form", values);
        if (!driver?.id) {
            values.password = "abc123";
        } else {
            values.phonenumber = undefined;
        }
        if (await driverRequest.post(values)) {
            toast.success("Driver" + (driver?.id ? " updated " : " added ") + "successfully");
            onOk();
        }
    }

    useEffect(() => {
        console.log(driver);
        if (driver?.id) {
            form.setFieldsValue({
                name: driver?.user?.name,
                model: driver?.model,
                year: driver?.year,
                noPlate: driver?.noPlate,
                TotalCapacity: driver?.totalCapacity,
                colour: driver?.colour,
                brandId: driver?.brandId,
                phonenumber: driver?.user?.phonenumber?.replace("+", ""),

            })
        }
    }, [driver]);


    return <Modal title={(driver?.id ? "Edit" : "Add") + " Driver"} footer={null} visible={modalVisible} onCancel={onCancel}>
        <Form form={form} onFinish={onFinish} layout='vertical'>

            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />

            </Form.Item>
            {!driver?.id &&
                <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
            }
            {/* {!driver?.id &&
                <Form.Item label="Password" name="password" rules={[{ required: true }]}>
                    <Input type="password" />
                </Form.Item>
            } */}

            <small className="text-red-500">Please enter phone number with country code included (eg +27123456789)</small>

            <Form.Item label="Phone" name="phonenumber" rules={[{ required: true }]}>
                <Input disabled={driver?.id} type="number" />
            </Form.Item>

            <Form.Item label="Vehicle Model" name="model" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Vehicle Manufacture Year" name="year" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Vehicle Registration Number" name="noPlate" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Total Capacity (Excluding Driver)" name="TotalCapacity" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label="Colour" name="colour" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            {/* {!driver.id && user.type === UserTypes.ADMIN &&
                <Form.Item required label="Organization" name="OrgId">
                    <Select
                        loading={orgRq?.loading}
                        placeholder="Select a organization"
                        options={orgRq?.data?.list?.map(O => ({ label: O.name, value: O.id })) ?? []}
                    />
                </Form.Item>} */}
            <Form.Item rules={[{ required: true, message: "Please select a brand" }]} label="Brand" name="brandId">
                <Select
                    className='w-full'
                    showSearch
                    placeholder="Select Brand"
                    showArrow={false}
                    filterOption={false}
                    notFoundContent={null}
                    onSearch={(s) => {
                        if (s)
                            setSearchText(s)
                    }}
                >
                    {brandRequest?.data?.map && brandRequest?.data?.map(d => <Select.Option key={d.id} >{d.name}</Select.Option>)}
                </Select>
            </Form.Item>
            <div className='w-full flex flex-row justify-end'>
                <Button className='mr-5' onClick={onCancel}>Cancel</Button>
                <Button loading={driverRequest?.loading} htmlType='submit' type='primary'>{driver?.id ? "Edit" : "Add"}</Button>
            </div>
        </Form>
    </Modal>
}


