import { Button } from "antd";
import { UserTypes } from "../constants/general";

export const RoutesCol = (assignRoute, openEditModal, editRoute, type) => [
    {
        title:"ID",
        dataIndex:'id',
        key:"id",
        width:'10%'
    },
    {
        title: "From",
        dataIndex:'departureName',
        key:'from',
        width:'20%'
    },
    {
        title: "To",
        dataIndex:'destinationName',
        key:'to',
        width:'20%'
    },
    {
        title: "Drivers Registered",
        dataIndex:'registeredDrivers',
        key:'drivers',
        width:'20%'
    },
    {
        title: "Fare",
        dataIndex:'amount',
        key:'fare',
        render: (text) => 'R' + text,
        width:'10%'
    },
    {
        title: "Actions",
        render: (text, record) => <div className="flex flex-row gap-5">
            <Button type='primary' onClick={() => assignRoute(record.id)}>Assign</Button>
            <Button  onClick={() => openEditModal(record.id)}>Edit Onboarding</Button>
            { type === UserTypes.ADMIN  && <Button  onClick={() => editRoute(record)}>Edit Route</Button>}
        </div>,
        key:'actions',
        width:'20%'
    },
]