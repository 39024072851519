import React, { useContext } from 'react';
import { Layout, Menu, Breadcrumb, Typography, Button } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import { Assets } from '../assets';
import { UserContext } from '../App';
import { UserTypes } from '../constants/general';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;


export const DashboardScreen = ({Routes}) => {
    const navigate = useNavigate();
    const {setUser, user} = useContext(UserContext);
    return <Layout style={{ minHeight: '100vh' }}>
        <Header className="header">
            <div className='w-full py-2 h-full flex-row flex items-center justify-between'>
                <img className='w-24 h-full object-contain' src={Assets.logo}/>
                <div className='flex flex-row items-center'>
                    <span  className='text-white font-semibold mr-3'>Hello {user.type === UserTypes.ADMIN?"Admin":user.name}!</span>
                    <Button onClick={e => setUser({})} type='primary'>Sign Out</Button>
                </div>
            </div>
        </Header>
        <Layout>
            <Sider width={200} className="site-layout-background">
                <Menu
                    onSelect={(e) => navigate(e.key)}
                    mode="inline"
                    style={{ height: '100%', borderRight: 0 }}
                >
                    {Routes?.map(R => <Menu.Item key={R.link} icon={R.icon}>{R.name}</Menu.Item>)}
                </Menu>
            </Sider>
            <Layout style={{ padding: '0 24px 24px' }}>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    </Layout>
}