import { Button, Form, Input, Modal, Select, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { RidesCols } from '../columns/rides.cols';
import { RidesData } from '../constants/datas';
import { TxyTable } from '../components/table.component';
import { useGetPaginated, usePost, usePut } from '../hooks/useRest.hook';
import { EndPoints } from '../constants/endpoints';
import { Constants } from '../constants/enums';
import { useContext } from 'react';
import { UserContext } from '../App';
import { UserTypes } from '../constants/general';
export const RidesScreen = () => {
    var ridesRequest = useGetPaginated(EndPoints.ALL_ORDERS);
    var updateRequest = usePut(EndPoints.UPDATE_ORDER);
    const { user } = useContext(UserContext);

    const [form] = Form.useForm();
    const [updateModal, setUpdateModal] = useState(false);

    const _update = (orderId, orderStatus) => {
        console.log("Setting Field", { orderId, orderStatus })
        form.setFieldsValue({ orderId, orderStatus })
        setUpdateModal(true);
    }

    const _onFinish = async values => {
        await updateRequest.post(values);
        setUpdateModal(false);
        ridesRequest.load(ridesRequest.pageNo, ridesRequest.pageSize);
    }


    return <div className='flex flex-col w-full'>
        <Typography.Title className='mb-5'>Rides</Typography.Title>
        <TxyTable cols={RidesCols(_update).filter(F => user.type === UserTypes.ADMIN || F.key !== "actions" )} {...ridesRequest} />

        <Modal title="Update Ride State" footer={null} visible={updateModal} onCancel={() => {
            form.resetFields();
            setUpdateModal(false);
        }}>
            <Form form={form} onFinish={_onFinish} layout='vertical'>
                <Form.Item label="Order ID" name="orderId" required>
                    <Input disabled />
                </Form.Item>
                <Form.Item label="Status" name="orderStatus" required>
                    <Select placeholder="Select a State">
                        {Constants.OrderStatusInfo.map(({ name }, I) => <Select.Option value={I}>{name}</Select.Option>)}
                    </Select>
                </Form.Item>
                <div className='w-full flex flex-row justify-end'>
                    <Button loading={updateRequest.loading} htmlType='submit' type='primary' >Update</Button>
                </div>
            </Form>
        </Modal>
    </div>
}
