import { Card, Typography } from 'antd';
import React from 'react';
import {useGet} from '../hooks/useRest.hook';
import {EndPoints} from '../constants/endpoints';
import {PieChart, Pie, Sector, Cell,  Area, AreaChart, CartesianGrid, Legend, Line, LineChart, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
const data = [
    {
        "name": "9 AM",
        "uv": 4000,
        "Rides": 2400,
        "amt": 2400
    },
    {
        "name": "10 AM",
        "uv": 3000,
        "Rides": 1398,
        "amt": 2210
    },
    {
        "name": "11 AM",
        "uv": 2000,
        "Rides": 9800,
        "amt": 2290
    },
    {
        "name": "12 PM",
        "uv": 2780,
        "Rides": 3908,
        "amt": 2000
    },
    {
        "name": "1 PM",
        "uv": 1890,
        "Rides": 4800,
        "amt": 2181
    },
    {
        "name": "2 PM",
        "uv": 2390,
        "Rides": 3800,
        "amt": 2500
    },
    {
        "name": "3 PM",
        "uv": 3490,
        "Rides": 4300,
        "amt": 2100
    }
]
const data2 = [
    {
        "subject": "BMW",
        "A": 120,
        "B": 110,
        "fullMark": 150
    },
    {
        "subject": "Toyota",
        "A": 98,
        "B": 130,
        "fullMark": 150
    },
    {
        "subject": "Honda",
        "A": 86,
        "B": 130,
        "fullMark": 150
    },
    {
        "subject": "Suzuki",
        "A": 99,
        "B": 100,
        "fullMark": 150
    },

]
export const OverviewScreen = () => {
    const metricsResponse = useGet(EndPoints.METRICS);
    const brandDistributionResponse = useGet(EndPoints.BRANDS_DIST);
    return <div className="w-full h-full flex flex-col justify-start">
        <Typography.Title>Welcome Admin!</Typography.Title>
        <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
            <Card title="Total Routes">
                <span className='text-3xl w-full font-bold text-center'>{metricsResponse?.data?.routeCount}</span>
            </Card>
            <Card title="Total Drivers">
                <span className='text-3xl font-bold text-center'>{metricsResponse?.data?.driverCount}</span>
            </Card>
            <Card title="Total Customers">
                <span className='text-3xl font-bold text-center'>{metricsResponse?.data?.userCount}</span>
            </Card>
        </div>
        <div className='flex flex-col lg:flex-row w-full py-3'>
            {/* <Card title="Active Rides By Time" className='w-full lg:w-2/3'>
                <ResponsiveContainer height={300} width="100%">
                    <LineChart data={data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Rides" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </Card> */}
            <Card title="Brand Distribution of Vehicles" className='w-full lg:w-1/3'>
                <ResponsiveContainer height={300} width="100%">
                    <RadarChart outerRadius={90} data={brandDistributionResponse.data?.brandDistributionRes?.map(V => ({
                        subject: V?.name,
                        A: V?.brandVehicleCount,
                        fullMark: brandDistributionResponse?.data?.totalVehicles
                    }))}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" />
                        <Radar name="Brands" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                        <Legend />
                    </RadarChart>
                </ResponsiveContainer>
            </Card>
        </div>
        {/* <div className='flex flex-col lg:flex-row w-full py-3'>
            <Card title="Active Drivers By Time" className='w-full lg:w-1/2'>
                <ResponsiveContainer height={300} width="100%">
                    <LineChart data={data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line name='Active Drivers' type="monotone" dataKey="Rides" stroke="#FFA500" />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
            <Card title="Avg Rides Per Route" className='w-full lg:w-1/2'>
                <ResponsiveContainer height={300} width="100%">
                    <AreaChart width={730} height={250} data={data}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#006400" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#006400" stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#ffbf00" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#ffbf00" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Area name="6th Road -> Murre Road" type="monotone" dataKey="uv" stroke="#BFFF00" fillOpacity={1} fill="url(#colorUv)" />
                        <Area name="Iqbal Avenue -> Kashmir Road" type="monotone" dataKey="Rides" stroke="#FFFF00" fillOpacity={1} fill="url(#colorPv)" />
                    </AreaChart>
                </ResponsiveContainer>
            </Card>
        </div> */}
    </div>
}