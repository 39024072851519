import { Button } from "antd";

export const BrandsCols = (edit,remove) => [
    {
        title:"ID",
        dataIndex:'id',
        key:"id",
        width:'10%'
    },
    {
        title: "Name",
        dataIndex:'name',
        key:'from',
        width:'30%'
    },
    {
        title: "Actions",
        dataIndex:'actions',
        render: (text, record) => <div className="flex flex-row gap-5">
            <Button onClick={() => edit(record)} type='primary'>Edit</Button>
            <Button onClick={() => remove(record)} style={{color:'white', backgroundColor:'red'}}>Delete</Button>
        </div>,
        key:'actions',
        width:'20%'
    },
]