import { AntDesignOutlined,BarChartOutlined, BankFilled,CarFilled, FormatPainterOutlined, MoneyCollectFilled, ProfileOutlined, UserOutlined, } from '@ant-design/icons';
import React from 'react';
import { RoutesScreen } from '../screens/routes.screen';
import { UsersScreen } from '../screens/users.screen';
import { DriversScreen } from '../screens/drivers.screen';
import { BrandsScreen } from '../screens/brands.screen';
import { ModelsScreen } from '../screens/models.screen';
import { RidesScreen } from '../screens/rides.screen';
import { PaymentsScreen } from '../screens/payments.screen';
import { OverviewScreen } from '../screens/overview.screen';
import { OrgsScreen } from '../screens/orgs.screen';
export const Routes = [
    {
        link: "",
        name: "Overview",
        icon: <BarChartOutlined />,
        screen: <OverviewScreen />,
    },
    {
        link: "routes",
        name: "Routes",
        icon: <AntDesignOutlined />,
        screen: <RoutesScreen />,
    },
    {
        link: "users",
        name: "Users",
        icon: <UserOutlined />,
        screen: <UsersScreen />
    },
    {
        link: "drivers",
        name: "Drivers",
        icon: <CarFilled />,
        screen: <DriversScreen />
    },
    {
        link: "brands",
        name: "Brands",
        icon: <CarFilled />,
        screen: <BrandsScreen />
    },
    // {
    //     link: "models",
    //     name: "Models",
    //     icon: <CarFilled />,
    //     screen: <ModelsScreen />
    // },
    // {
    //     link: "payments",
    //     name: "Payments",
    //     icon: <MoneyCollectFilled />,
    //     screen: <PaymentsScreen />
    // },
    {
        link: "rides",
        name: "Rides",
        icon: <CarFilled />,
        screen: <RidesScreen />
    }

]