import React from 'react';
import ReactLoading from 'react-loading';
import { Assets } from '../assets';
export const Loader = () => {
    return <div className='self-center h-96 w-96 flex justify-center items-center'>
        <ReactLoading type='bubbles' color={'#F8BC46'} height={'30%'} width={'30%'} />
    </div>
}
export const FullLoader = () => {
    return <div className='flex flex-col justify-center items-center' style={{ height: '100vh', backgroundColor: 'white', width: '100vw', position: 'absolute', zIndex: 100 }}>
        <img className='w-32 mb-10  object-contain' src={Assets.logo} />
        <ReactLoading type='spinningBubbles' color={'#001529'} height={'5%'}  width={'5%'} />
    </div>
}